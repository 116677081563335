<template>
  <v-form class="fill-height" @submit.prevent>
    <gw-form-observer
      :loading="bLoading"
      hide-top-actions
      hide-bottom-actions
      @save="save"
    >
      <v-row>
        <v-col cols="12" lg="4" md="6">
          <company-select :value="companyId" @change="onSelectCompany" />
        </v-col>

        <v-divider vertical />

        <v-col v-if="companyId" cols="12" lg="8" md="6">
          <company-invoice-types
            :value="obCompany"
            :key="`company.${companyId}`"
          />
        </v-col>
      </v-row>
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompanyInvoiceTypes from "@/modules/companies/components/tabs/InvoiceTypes.vue";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import { AppModule } from "@/store/app";
import { Company } from "@planetadeleste/vue-mc-gw";
import CompanySelect from "@/modules/companies/components/CompanySelect.vue";

@Component({
  components: { CompanySelect, CompanyInvoiceTypes },
})
export default class InvoiceTypes extends Mixins(CompaniesMixin) {
  bLoading = false;

  obCompany: Company = new Company();

  get companyId(): number | undefined {
    return this.obCompany ? this.obCompany.id : undefined;
  }

  onSelectCompany(obData: Company) {
    this.obCompany = obData;
  }

  mounted() {
    this.obCompany = AppModule.company;
  }
}
</script>
